import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './SubscriptionDetails.css';

const SubscriptionDetails = ({ subscription, onBack }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  const [newFan, setNewFan] = useState('');
  const [personalityFields, setPersonalityFields] = useState({
    profession: '',
    lieuDeVie: '',
    hobbies: '',
    prenom: '',
    surnom: '',
    age: '',
    sexe: 'une femme' // Par défaut, 'femme' est sélectionné
  });

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const parseNoteModelToFields = (noteModel) => {
    const fields = {
      profession: '',
      lieuDeVie: '',
      hobbies: '',
      prenom: '',
      surnom: '',
      age: '',
      sexe: 'une femme' // Par défaut, 'femme' est sélectionné
    };

    if (!noteModel) return fields;

    // Try to extract profession
    const professionMatch = noteModel.match(/Tu es ([^,.]+)/i);
    if (professionMatch && professionMatch[1]) {
      // Check if it's not "un homme" or "une femme" (sexe field)
      if (!professionMatch[1].includes('homme') && !professionMatch[1].includes('femme')) {
        fields.profession = professionMatch[1];
      }
    }

    // Try to extract lieu de vie
    const lieuMatch = noteModel.match(/tu vis à ([^,.]+)/i);
    if (lieuMatch && lieuMatch[1]) {
      fields.lieuDeVie = lieuMatch[1];
    }

    // Try to extract hobbies
    const hobbiesMatch = noteModel.match(/tu aimes ([^,.]+)/i);
    if (hobbiesMatch && hobbiesMatch[1]) {
      fields.hobbies = hobbiesMatch[1];
    }

    // Try to extract prénom
    const prenomMatch = noteModel.match(/ton prénom est ([^,.]+)/i);
    if (prenomMatch && prenomMatch[1]) {
      fields.prenom = prenomMatch[1];
    }

    // Try to extract surnom
    const surnomMatch = noteModel.match(/ton surnom est ([^,.]+)/i);
    if (surnomMatch && surnomMatch[1]) {
      fields.surnom = surnomMatch[1];
    }

    // Try to extract age
    const ageMatch = noteModel.match(/tu as ([0-9]+) ans/i);
    if (ageMatch && ageMatch[1]) {
      fields.age = ageMatch[1];
    }

    // Try to extract sexe
    const sexeHommeMatch = noteModel.match(/tu es (un homme)/i);
    const sexeFemmeMatch = noteModel.match(/tu es (une femme)/i);
    if (sexeHommeMatch) {
      fields.sexe = 'un homme';
    } else if (sexeFemmeMatch) {
      fields.sexe = 'une femme';
    }

    return fields;
  };

  // Construct note_model from personality fields
  const constructNoteModel = () => {
    let text = '';

    // Add profession if provided
    if (personalityFields.profession) {
      text += `Tu es ${personalityFields.profession}`;
      // Add period if this is the only field
      if (!personalityFields.lieuDeVie && !personalityFields.hobbies &&
          !personalityFields.prenom && !personalityFields.surnom &&
          !personalityFields.age && !personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add lieu de vie if provided
    if (personalityFields.lieuDeVie) {
      text += `tu vis à ${personalityFields.lieuDeVie}`;
      // Add period if this is the last field
      if (!personalityFields.hobbies && !personalityFields.prenom &&
          !personalityFields.surnom && !personalityFields.age && !personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add hobbies if provided
    if (personalityFields.hobbies) {
      text += `tu aimes ${personalityFields.hobbies}`;
      // Add period if this is the last field
      if (!personalityFields.prenom && !personalityFields.surnom &&
          !personalityFields.age && !personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add prénom if provided
    if (personalityFields.prenom) {
      text += `ton prénom est ${personalityFields.prenom}`;
      // Add period if this is the last field
      if (!personalityFields.surnom && !personalityFields.age && !personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add surnom if provided
    if (personalityFields.surnom) {
      text += `ton surnom est ${personalityFields.surnom}`;
      // Add period if this is the last field
      if (!personalityFields.age && !personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add age if provided
    if (personalityFields.age) {
      text += `tu as ${personalityFields.age} ans`;
      // Add period if this is the last field
      if (!personalityFields.sexe) {
        text += '.';
      } else {
        text += ', ';
      }
    }

    // Add sexe if provided
    if (personalityFields.sexe) {
      text += `tu es ${personalityFields.sexe}.`;
    }

    // If no fields were filled, return empty string
    return text;
  };

  const fetchSubscriptionDetails = async () => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
      const response = await axios.get(`https://unbot.io/api/v1.0/subscription/${encodeURIComponent(subscription._id)}`, config);
      setSubscriptionDetails(response.data);
      setEditedDetails(response.data);

      // Initialize personality fields
      setPersonalityFields(parseNoteModelToFields(response.data.note_model));

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    // Ensure personality fields are initialized with current values
    setPersonalityFields(parseNoteModelToFields(subscriptionDetails.note_model));
    setIsEditing(true);
  };

  const handleSave = async () => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
      // Construct note_model from personality fields
      const constructedNoteModel = constructNoteModel();

      // Update the editedDetails with the constructed note_model
      const updatedDetails = {
        ...editedDetails,
        note_model: constructedNoteModel
      };

      await axios.patch(`https://unbot.io/api/v1.0/subscription/${encodeURIComponent(subscription._id)}`, updatedDetails, config);
      setSubscriptionDetails(updatedDetails);
      setEditedDetails(updatedDetails);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };

  const handleCancel = () => {
    setEditedDetails(subscriptionDetails);
    // Reset personality fields to their original values
    setPersonalityFields(parseNoteModelToFields(subscriptionDetails.note_model));
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle changes to personality fields
  const handlePersonalityFieldChange = (field, value) => {
    setPersonalityFields(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddFan = () => {
    const fanName = newFan.trim().toLowerCase();
    if (fanName && !editedDetails.fan_list.includes(fanName)) {
      handleChange('fan_list', [...editedDetails.fan_list, fanName]);
      setNewFan('');
    }
  };

  const handleRemoveFan = (fanToRemove) => {
    handleChange('fan_list', editedDetails.fan_list.filter(fan => fan !== fanToRemove));
  };

  const calculateDaysSinceSubscribed = (date) => {
    const subscriptionDate = new Date(date);
    const currentDate = new Date();
    const differenceInTime = currentDate - subscriptionDate;
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  }

  const calculateDaysUntilRenewal = (date) => {
    const subscriptionDate = new Date(date);
    const now = new Date();
    const nextRenewalDate = new Date();
    nextRenewalDate.setDate(subscriptionDate.getDate());
    if (nextRenewalDate <= now) {
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);
    }

    const currentDate = new Date();

    if (currentDate.toDateString() === nextRenewalDate.toDateString()) {
      return 0;
    }

    const differenceInTime = nextRenewalDate - currentDate;
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  }

  const getModeSelectionText = (mode) => {
    switch (mode) {
      case 'all':
        return 'Répondre à tous les messages';
      case 'whitelist':
        return 'Répondre uniquement aux fans dans la liste';
      case 'blacklist':
        return 'Répondre uniquement aux fans qui ne sont pas dans la liste';
      default:
        return mode;
    }
  }

  const getVenteModeText = (mode) => {
    switch (mode) {
      case 'notif':
        return 'Lorsque il y a une vente potentiel vous recevrez une notification';
      case 'auto':
        return 'Le robot fera automatiquement la vente';
      default:
        return mode;
    }
  }

  if (isLoading || !subscriptionDetails) {
    return <div className="subscription-details-container">Chargement...</div>;
  }

  return (
    <div className="subscription-details-container">
      <button className="back-button" onClick={onBack}>
        ← Retour
      </button>

      <h1 className="details-title">Détails de l'abonnement</h1>

      <div className="details-card">
        <div className="details-header">
          <h2 className="creator-name">{subscriptionDetails?.creator?.name ?? ""}</h2>
          {!isEditing ? (
            <button className="edit-button" onClick={handleEdit}>
              Modifier
            </button>
          ) : (
            <div className="edit-actions">
              <button className="cancel-button" onClick={handleCancel}>
                Annuler
              </button>
              <button className="save-button" onClick={handleSave}>
                Enregistrer
              </button>
            </div>
          )}
        </div>

        <div className="details-section">
          <h3>Informations générales</h3>
          <p>Date d'abonnement : {new Date(subscriptionDetails.date).toLocaleDateString()}</p>
          <p>Jours d'abonnement : {calculateDaysSinceSubscribed(subscriptionDetails.date)} jours</p>
          <p>Prochain renouvellement : dans {calculateDaysUntilRenewal(subscriptionDetails.date)} jours</p>
        </div>

        <div className="details-section">
          <h3>Configuration du bot</h3>
          {isEditing ? (
            <>
              <div className="edit-field">
                <label>Mode de sélection :</label>
                <select
                  value={editedDetails.mode_selection}
                  onChange={(e) => handleChange('mode_selection', e.target.value)}
                >
                  <option value="all">Répondre à tous les messages</option>
                  <option value="whitelist">Répondre uniquement aux fans dans la liste</option>
                  <option value="blacklist">Répondre uniquement aux fans qui ne sont pas dans la liste</option>
                </select>
              </div>
              <div className="edit-field">
                <label>Mode de vente :</label>
                <select
                  value={editedDetails.vente_mode}
                  onChange={(e) => handleChange('vente_mode', e.target.value)}
                >
                  <option value="notif">Lorsque il y a une vente potentiel vous recevrez une notification</option>
                  <option value="auto">Le robot fera automatiquement la vente</option>
                </select>
              </div>
              <div className="edit-field">
                <label>Statut du robot :</label>
                <select
                  value={editedDetails.monitor_status ? 'true' : 'false'}
                  onChange={(e) => handleChange('monitor_status', e.target.value === 'true')}
                >
                  <option value="true">Actif</option>
                  <option value="false">Inactif</option>
                </select>
              </div>
            </>
          ) : (
            <>
              <p>Mode de sélection : {getModeSelectionText(subscriptionDetails.mode_selection)}</p>
              <p>Mode de vente : {getVenteModeText(subscriptionDetails.vente_mode)}</p>
              <p>Statut du robot : {subscriptionDetails.monitor_status ? 'Actif' : 'Inactif'}</p>
            </>
          )}
        </div>

        <div className="details-section">
          <h3>Liste des fans</h3>
          {isEditing ? (
            <div className="fan-list-edit">
              <div className="fan-list-input">
                <input
                  type="text"
                  value={newFan}
                  onChange={(e) => setNewFan(e.target.value)}
                  placeholder="Ajouter un fan"
                  onKeyPress={(e) => e.key === 'Enter' && handleAddFan()}
                />
                <button className="add-fan-button" onClick={handleAddFan}>
                  Ajouter
                </button>
              </div>
              <div className="fan-list">
                {editedDetails.fan_list.length > 0 ? (
                  editedDetails.fan_list.map((fan, index) => (
                    <div key={index} className="fan-item">
                      <span>{fan}</span>
                      <button
                        className="remove-fan-button"
                        onClick={() => handleRemoveFan(fan)}
                      >
                        ×
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="no-fans-message">Aucun fan dans la liste</p>
                )}
              </div>
            </div>
          ) : (
            <div className="fan-list">
              {subscriptionDetails.fan_list.length > 0 ? (
                subscriptionDetails.fan_list.map((fan, index) => (
                  <div key={index} className="fan-item">
                    <span>{fan}</span>
                  </div>
                ))
              ) : (
                <p className="no-fans-message">Aucun fan dans la liste</p>
              )}
            </div>
          )}
        </div>

        <div className="details-section">
          <h3>Personnalité du bot</h3>
          {isEditing ? (
            <div className="personality-fields">
              <div className="edit-field">
                <label>Profession:</label>
                <input
                  type="text"
                  value={personalityFields.profession}
                  onChange={(e) => handlePersonalityFieldChange('profession', e.target.value)}
                  placeholder="Étudiante en droit, Médecin, Ingénieur..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Lieu de vie:</label>
                <input
                  type="text"
                  value={personalityFields.lieuDeVie}
                  onChange={(e) => handlePersonalityFieldChange('lieuDeVie', e.target.value)}
                  placeholder="Paris, New York, à la campagne..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Hobbies:</label>
                <input
                  type="text"
                  value={personalityFields.hobbies}
                  onChange={(e) => handlePersonalityFieldChange('hobbies', e.target.value)}
                  placeholder="la musique, le sport, la lecture..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Prénom:</label>
                <input
                  type="text"
                  value={personalityFields.prenom}
                  onChange={(e) => handlePersonalityFieldChange('prenom', e.target.value)}
                  placeholder="Marie, Jean, Alex..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Surnom:</label>
                <input
                  type="text"
                  value={personalityFields.surnom}
                  onChange={(e) => handlePersonalityFieldChange('surnom', e.target.value)}
                  placeholder="Mimi, Jo, Loulou..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Age:</label>
                <input
                  type="text"
                  value={personalityFields.age}
                  onChange={(e) => handlePersonalityFieldChange('age', e.target.value)}
                  placeholder="25, 30, 40..."
                  className="personality-input"
                />
              </div>

              <div className="edit-field">
                <label>Sexe:</label>
                <select
                  value={personalityFields.sexe}
                  onChange={(e) => handlePersonalityFieldChange('sexe', e.target.value)}
                  className="personality-input"
                >
                  <option value="une femme">Femme</option>
                  <option value="un homme">Homme</option>
                </select>
              </div>

              <div className="preview-section">
                <label>Aperçu:</label>
                <p className="note-model-preview">{constructNoteModel() || "Aucun texte généré"}</p>
              </div>
            </div>
          ) : (
            <p className="note-model">{subscriptionDetails.note_model}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails;